import cn from 'classnames';

import Image from '@/components/base/elements/Image/Image';
import Section from '@/components/base/layouts/Section/Section';
import {TwoColumn} from '@/components/base/layouts/TwoColumn/TwoColumn';
import ButtonGroup from '@/components/base/modules/ButtonGroup/ButtonGroup';
import HeadingGroup from '@/components/base/modules/HeadingGroup/HeadingGroup';
import type {HeroProps} from '@/components/base/sections/Hero/types';
import WistiaVideoPlayer from '@/components/shared/WistiaVideoPlayer';
import {ColorSchemeEnum} from '@/enums';
import useCtasOrSignup from '@/hooks/useCtasOrSignup/useCtasOrSignup';
import {heroBackgroundByTheme} from '@/stylesheets/brochureV2/colorSchemes';
import {twMerge} from '@/stylesheets/twMerge';
import {componentName} from '@/components/base/sections/Hero/types';
import type {Mode} from '@/components/base/types';
import type {IncentiveDetails} from '@/pages/shopify.com/($locale)/pos/components/Hero/PosIncentivesPromoBanner';
import PosIncentivesPromoBanner from '@/pages/shopify.com/($locale)/pos/components/Hero/PosIncentivesPromoBanner';
import {useRetailUnifiedFunnel} from '@/hooks/experiments/useRetailUnifiedFunnel';
import {useTranslations} from '@/hooks/useTranslations';

interface PosHeroFloatingImageProps extends HeroProps {
  incentiveDetails?: IncentiveDetails | null;
}

export default function PosHeroFloatingImage({
  className,
  colorScheme,
  ctas,
  headingGroupProps,
  headingHtml,
  images,
  kicker,
  mode = 'light',
  signup: signupData,
  subheadHtml,
  videos,
  isBleedImage,
  displayPromoBanner = false,
  isPillarPage = false,
  incentiveDetails,
  ...props
}: PosHeroFloatingImageProps) {
  const buttonGroupProps = useCtasOrSignup(signupData || {}, ctas);
  const {t} = useTranslations();
  const {isRetailUnifiedFunnelExperiment} = useRetailUnifiedFunnel();
  const contactSalesCta = {
    ...(isRetailUnifiedFunnelExperiment
      ? {
          buttons: [
            {
              text: t('pos:components.contactSalesCta'),
              href: '#contact-sales',
            },
          ],
        }
      : {}),
  };
  const scheme = colorScheme || ColorSchemeEnum.Light;
  const bannerMode =
    scheme === ColorSchemeEnum.Light ? colorScheme : ColorSchemeEnum.Dark;

  return (
    <Section
      className={twMerge(
        cn('relative pt-hero-top pb-3xl md:pb-0', {
          'pb-0': isBleedImage,
          'gap-0': incentiveDetails,
        }),
        heroBackgroundByTheme[scheme],
        className,
      )}
      errorBoundaryFullWidth={true}
      navbarPadding={true}
      data-component-name={componentName}
      {...props}
    >
      <TwoColumn>
        <TwoColumn.Col1 className="flex flex-col pt-3xl sm:col-span-6 my-auto md:py-3xl gap-y-md">
          {incentiveDetails && (
            <PosIncentivesPromoBanner
              mode={bannerMode as Mode}
              className="pb-lg hover:[&_a]:!text-inherit"
              incentiveDetails={incentiveDetails}
            />
          )}
          <HeadingGroup
            kicker={kicker as string}
            headingAs="h1"
            headingHtml={headingHtml as string}
            subheadHtml={subheadHtml as string}
            mode={mode}
            {...headingGroupProps}
          />
          {buttonGroupProps && (
            <ButtonGroup
              mode={mode}
              {...buttonGroupProps}
              {...contactSalesCta}
              displayPromoBanner={displayPromoBanner}
              isPillarPage={isPillarPage}
            />
          )}
        </TwoColumn.Col1>
        <TwoColumn.Col2
          className={cn('my-auto sm:col-start-1 sm:col-span-8 md:py-3xl', {
            '!py-0 flex-row h-full items-end': isBleedImage,
          })}
        >
          {images && images.length > 0 && (
            <Image className="mx-auto" {...images[0]} />
          )}
          {videos && videos.length > 0 && (
            <WistiaVideoPlayer
              videoId={videos[0].videoId as string}
              {...videos[0].options}
            />
          )}
        </TwoColumn.Col2>
      </TwoColumn>
    </Section>
  );
}
